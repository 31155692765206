<template>

  <slot name="give" :givePoint="givePoint" :getPoints="getPoints" :actionRequired="data.actionRequired" :removePoint="data.removePoint"></slot>

  <!-- Modal -->
  <div class="modal" id="corner-menu" tabindex="-1" aria-labelledby="cornerMenuLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-body" style="padding: 0;">
          <div class="row g-0">

            <!-- Log mode -->
            <div v-if="data.showLog" class="col-6 p-3" style="height: 100vh; overflow: scroll">
              <h2>{{ t("log")}}</h2>
              <button v-if="data.actionRequired" class="btn btn-warning" @click="clearActionRequired">{{ t("clear_action_required") }}</button>
              <div class="row" v-for="m in [...data.messages].reverse()">
                <div class="col-12"><small style="color: gray;">{{ m.time }}</small></div>
                <div class="offset-1 col-11" v-html="m.text"></div>
              </div>
            </div>

            <!-- Loading points -->
            <div v-else-if="data.loadingPoints" class="col-6 p-3">
              {{ t("loading") }}...
            </div>

            <!-- Points mode -->
            <div v-else class="col-6">
              <slot name="view" :points="data.points" :deductions="data.deductions" :pointComponent="pointComponent" :redTot="redTot" :blueTot="blueTot"></slot>
            </div>

            <div class="col-6 text-center">
              <div class="row align-items-center">
                <button @click="data.removePoint = !data.removePoint" data-bs-dismiss="modal" class="col-6 vph-2 wb menu-remove-point">{{ t("remove_point") }}</button>
                <button @click="data.removePoint = false; data.showLog = false" data-bs-dismiss="modal" class="col-6 vph-2 wb menu-back">{{ t("back") }}</button>
                <button @click="data.showLog = !data.showLog" class="col-6 vph-2 wb menu-log">{{ data.showLog ? t("points") : t("log") }}</button>
                <button @click="exit" data-bs-dismiss="modal" class="col-6 vph-2 wb menu-exit">{{ t("exit") }}</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed, onMounted, onUnmounted, reactive} from "vue";
import moment from "moment/moment";
import common from "@/common";
import axios from "axios";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

const {t} = useI18n()
const props = defineProps(['online', 'points'])
const route = useRoute()
const router = useRouter()
const data = reactive({
  serial: 0,
  messages: [],
  points: [],
  deductions: {
    red: 0,
    blue: 0,
  },

  showLog: false,
  actionRequired: false,
  removePoint: false,
  loadingPoints: true,

  currentCategoryName: "",
  currentMatchNumber: 0,
});

function redTotWoDeductions() {
  let sum = 0
  for (let p in data.points) {
    let r  = data.points[p].red
    sum += r["1"] + r["2"] * 2 + r["3"] * 3
  }
  return sum
}
const redTot = computed(() => {
  return redTotWoDeductions() - data.deductions.red
})
function blueTotWoDeductions() {
  let sum = 0
  for (let p in data.points) {
    let b = data.points[p].blue
    sum += b["1"] + b["2"] * 2 + b["3"] * 3
  }
  return sum
}
const blueTot = computed(() => {
  return blueTotWoDeductions() - data.deductions.blue
})

function pointComponent(color, value) {
  let result = 0
  for (let p in data.points) {
    result += data.points[p][color][value]
  }
  return result
}

function givePoint(redNotBlue, point) {
  let value = data.removePoint ? -point : point
  let color = redNotBlue ? "red" : "blue"
  message("Point " + color + " " + value)
  data.points[data.points.length -1][color][point] += data.removePoint ? -1 : 1
  if (typeof js_bridge !== 'undefined') {
    js_bridge.onPoint(point)
  }
  data.removePoint = false

  if (!props.online) {
    return
  }

  data.serial += 1
  axios.post(common.jirugiApi() + "/v1/" + route.params.key + "/give_points", {
    redNotBlue: redNotBlue,
    points: value,
    serial: data.serial,
  }).then(resp => {
    if (resp.data.data.serial !== data.serial) {
      console.log('Ignoring response. Newer has been sent. received: ', resp.data.data.serial, "waiting for: ", data.serial)
      return
    }
    updatePoints(resp.data.data, true)
  }).catch(err => {
    console.log(err)
    data.actionRequired = true
    message("ERROR give value", err)
  })
}

function updatePoints(serverData, validate) {
  if (serverData.match !== data.currentMatchNumber) {
    data.currentCategoryName = serverData['category']
    data.currentMatchNumber = serverData.match
    data.points = serverData.points
    validate = false
    message("Loaded match (" + data.currentCategoryName + ": " + serverData.match + ", id=" + serverData.matchId + ")")
  }

  data.deductions.red = serverData.deductions.red
  data.deductions.blue = serverData.deductions.blue

  if (validate) {
    let client = {red: {1: 0, 2: 0, 3: 0}, blue: {1: 0, 2: 0, 3: 0}}
    let server = {red: {1: 0, 2: 0, 3: 0}, blue: {1: 0, 2: 0, 3: 0}}
    let cs = ['red', 'blue']
    let ps = ["1", "2", "3"]
    for (let a in data.points) {
      for (let c in cs) {
        for (let p in ps) {
          client[cs[c]][ps[p]] += data.points[a][cs[c]][ps[p]]
        }
      }
    }
    for (let a in serverData.points) {
      for (let c in cs) {
        for (let p in ps) {
          server[cs[c]][ps[p]] += serverData.points[a][cs[c]][ps[p]]
        }
      }
    }
    for (let c in cs) {
      for (let p in ps) {
        if (client[cs[c]][ps[p]] !== server[cs[c]][ps[p]]) {
          data.actionRequired = true
          message("Point (" + cs[c] + " " + ps[p] + ") missmatch, client: " + client[cs[c]][ps[p]] + " server: " + server[cs[c]][ps[p]])
        }
      }
    }
  }
}

function getPoints() {
  if (!props.online) {
    data.loadingPoints = false
    return
  }

  data.loadingPoints = true
  axios.post(common.jirugiApi() + "/v1/" + route.params.key + "/get_corner_umpire_points").then(resp => {
    if (resp.data.length !== 0) {
      updatePoints(resp.data.data,false)
    }
    data.loadingPoints = false
  }).catch(err => {
    message("ERROR get points", err)
    data.actionRequired = true
    data.loadingPoints = false
  })
}

function clearActionRequired() {
  data.actionRequired = false
  data.showLog = false

  // exit the menu.
}

function exit() {
  message(
      "Leaving match (" + data.currentCategoryName + ": " + data.currentMatchNumber + ")<br />" +
      "RED: " + redTotWoDeductions() + ", BLUE: " + blueTotWoDeductions() + " (w/o deductions)<br />" +
      "RED: " + redTot + ", BLUE: " + blueTot + " (with deductions)"
  )
  router.go(-1)
}

function message(str, err) {
  if (err != null) {
    if (err?.response?.data?.message) {
      str += ": " + err.response.data.message
    } else {
      str += ": " + err
    }
  }
  data.messages.push({"time": moment().format("HH:mm:ss SSS"), "text": str})
  sessionStorage.setItem("messages", JSON.stringify(data.messages))
}

onMounted(() => {
  let m = sessionStorage.getItem("messages")
  if (m == null) {
    m = "[]"
  }
  data.messages = JSON.parse(m)
  document.querySelector("html").style.overflow = "hidden"
  getPoints()
  if (!props.online) {
    data.points.push({red: {1: 0, 2: 0, 3: 0}, blue: {1: 0, 2: 0, 3: 0}});
  }
})
onUnmounted(() => {
  document.querySelector("html").style.overflow = ""
})
</script>

<style lang="scss">


</style>
